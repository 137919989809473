import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  useStepFormSubmit,
  goToNextStep,
  getMemberAgeInYears,
  useFlashMessages,
  push,
} from 'utils'
import { ApplicationLayout, ReviewForms, ReviewConsent } from 'components'
import { AssistanceForm, SignaturesForm } from 'forms'
import {
  updateTenantApplication,
  fetchFiles,
  uploadFiles,
  destroyFile,
  completeApplication,
} from 'api'
import {
  STEP,
  SIGNATURES_ERROR_MESSAGE,
  SAVE_ERROR_MESSAGE,
  APPLICATION_COMPLETED_PATH,
} from 'config'
import { isEmpty } from 'lodash'

const propTypes = {
  tenantApplication: Types.tenantApplication.isRequired,
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
  tenant: Types.tenant.isRequired,
  householdIncomes: PropTypes.arrayOf(Types.householdIncome).isRequired,
  zeroIncomes: PropTypes.arrayOf(Types.zeroIncome).isRequired,
  householdAssets: PropTypes.arrayOf(Types.householdAsset).isRequired,
  tenantCaseFiles: PropTypes.arrayOf(Types.file).isRequired,
  caseNumber: PropTypes.string.isRequired,
  certType: PropTypes.string,
}
const defaultProps = {}

function ReviewAndSign({
  tenantApplication,
  householdMembers,
  tenant,
  householdIncomes,
  zeroIncomes,
  householdAssets,
  tenantCaseFiles,
  caseNumber,
  certType,
}) {
  const { showFlashFailure } = useFlashMessages()
  const [uploadedSignatures, setUploadedSignatures] = useState()
  const [memberIdUploading, setMemberIdUploading] = useState()
  const [signatureIdRemoving, setSignatureIdRemoving] = useState()
  const { handleSubmitUpdate } = useStepFormSubmit({
    updateRequest: updateTenantApplication,
  })

  const adultMembers = useMemo(
    () =>
      householdMembers.filter((member) => getMemberAgeInYears(member) >= 18),
    [householdMembers]
  )

  const fetchSignatures = useCallback(async () => {
    const membersSfids = adultMembers.flatMap((member) => member.sfid)
    try {
      const memberFiles = await fetchFiles(membersSfids)
      const signatures = memberFiles.filter((file) =>
        file.name.includes('signature')
      )
      setUploadedSignatures(signatures)
    } catch {
      showFlashFailure(SIGNATURES_ERROR_MESSAGE)
    }
  }, [adultMembers, showFlashFailure])

  const saveSignature = useCallback(
    async (file, memberId) => {
      setMemberIdUploading(memberId)
      try {
        await uploadFiles({ objectId: memberId, files: [file] })
        fetchSignatures()
      } catch (error) {
        showFlashFailure(SAVE_ERROR_MESSAGE)
      }
      setMemberIdUploading(null)
    },
    [fetchSignatures, showFlashFailure]
  )

  const removeSignature = useCallback(
    async (signatureId) => {
      setSignatureIdRemoving(signatureId)
      try {
        await destroyFile(signatureId)
        setUploadedSignatures(
          uploadedSignatures.filter(
            (signature) => signature.contentDocumentId !== signatureId
          )
        )
      } catch (error) {
        showFlashFailure(SAVE_ERROR_MESSAGE)
      }
      setSignatureIdRemoving(null)
    },
    [showFlashFailure, uploadedSignatures]
  )

  const hasAllSignatures = adultMembers.every(({ sfid }) => {
    if (!uploadedSignatures) return false

    return uploadedSignatures.some(
      ({ firstPublishLocationId }) => firstPublishLocationId === sfid
    )
  })

  useEffect(() => {
    fetchSignatures()
  }, [fetchSignatures])

  return (
    <>
      <Helmet>
        <title>Review & Sign</title>
      </Helmet>
      <div className="page-header">
        <h1>Review & Sign</h1>
        <p>
          Go through each section to make sure all of the correct information is
          represented. Next, if you received any assistance filling out this
          form, make note of it in the “Assistance Tracking” section. After
          reviewing all of the information, each household member above the age
          of 18 needs to provide their signature.
        </p>
      </div>
      <ReviewForms
        householdMembers={householdMembers}
        tenant={tenant}
        tenantApplication={tenantApplication}
        householdIncomes={householdIncomes}
        zeroIncomes={zeroIncomes}
        householdAssets={householdAssets}
        tenantCaseFiles={tenantCaseFiles}
        certType={certType}
      />
      <ReviewConsent />
      <AssistanceForm
        initialValues={{ tenantApplication }}
        onSubmit={async ({ tenantApplication }) => {
          if (!isEmpty(adultMembers) && !hasAllSignatures) {
            return showFlashFailure(
              'All signatures must be saved before continuing'
            )
          }

          const isSubmitSuccess = await handleSubmitUpdate({
            tenantApplication,
          })

          if (isSubmitSuccess) {
            if (tenantApplication.skipInterview) {
              await completeApplication({ caseNumber })
              push(APPLICATION_COMPLETED_PATH)
            } else {
              goToNextStep(STEP.INTERVIEW)
            }
          }
        }}
      >
        {!isEmpty(adultMembers) && (
          <SignaturesForm
            signatures={uploadedSignatures}
            saveSignature={saveSignature}
            removeSignature={removeSignature}
            adultMembers={adultMembers}
            memberIdUploading={memberIdUploading}
            signatureIdRemoving={signatureIdRemoving}
          />
        )}
      </AssistanceForm>
    </>
  )
}

ReviewAndSign.propTypes = propTypes
ReviewAndSign.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ReviewAndSign)
