import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Helmet } from 'react-helmet'
import { compose } from 'recompose'
import {
  withJsonApiProps,
  withLayout,
  useStepFormSubmit,
  useFlashMessages,
  push,
  goToNextStep,
  buildZeroIncomeHouseholdMemberSections,
} from 'utils'
import { ApplicationLayout, ZeroIncomeAccordionItem } from 'components'
import { every } from 'lodash'
import { Accordion } from 'react-accessible-accordion'
import { STEP } from 'config'
import { createZeroIncomes, updateZeroIncomes } from 'api'
import { ButtonArea, Button } from 'lp-components'

const propTypes = {
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function ZeroIncomes({ householdMembers }) {
  const { handleSubmitCrud } = useStepFormSubmit({
    createRequest: createZeroIncomes,
    updateRequest: updateZeroIncomes,
    successFlashMessage:
      'Zero Household Income section saved successfully! You may move on to the next section.',
  })
  const [completionStatusForSections, setCompletionStatusForSections] =
    useState({})

  const { showFlashFailure } = useFlashMessages()

  const setSectionCompletionStatus = useCallback(
    (sectionName, isSectionComplete) => {
      setCompletionStatusForSections((currentValue) => ({
        ...currentValue,
        [sectionName]: isSectionComplete,
      }))
    },
    []
  )

  const isFormComplete = every(
    completionStatusForSections,
    (isSectionComplete) => isSectionComplete
  )

  let filteredMembers = []
  if (every(householdMembers, (member) => member.noIncome && member.isAdult)) {
    filteredMembers = householdMembers.filter((member) => member.isAdult)
  } else {
    filteredMembers = householdMembers.filter(
      (member) => member.noIncome && member.isAdult
    )
  }

  return (
    <>
      <Helmet>
        <title>Zero Household Income</title>
      </Helmet>
      <div className="page-header">
        <h1>Zero Income</h1>
        <p>
          For each household member below, fill in their income information.
          This will include the type of income a hosehold member should be
          expecting and details if not.
        </p>
      </div>
      <Accordion
        preExpanded={filteredMembers.map(({ id }) => id)}
        allowZeroExpanded
        allowMultipleExpanded
      >
        {filteredMembers.map((householdMember) => (
          <ZeroIncomeAccordionItem
            key={householdMember.id}
            householdMember={householdMember}
            handleSubmitCrud={handleSubmitCrud}
            setSectionCompletionStatus={setSectionCompletionStatus}
            zeroIncomeHouseholdMemberSections={buildZeroIncomeHouseholdMemberSections(
              householdMembers
            )}
          />
        ))}
      </Accordion>
      <p className="continue-warning">
        Please confirm that you've saved any changes made before continuing.
      </p>
      <ButtonArea>
        <Button
          className="button-primary-outline"
          onClick={() => push(STEP.HOUSEHOLD_INCOME.path)}
        >
          Previous Step
        </Button>
        <Button
          onClick={async () => {
            if (!isFormComplete) {
              return showFlashFailure(
                'Please complete all zero income questions for every household member before continuing.'
              )
            }
            goToNextStep(STEP.HOUSEHOLD_ASSETS)
          }}
        >
          Continue
        </Button>
      </ButtonArea>
    </>
  )
}

ZeroIncomes.propTypes = propTypes
ZeroIncomes.defaultProps = defaultProps

export default compose(
  withJsonApiProps(),
  withLayout(ApplicationLayout)
)(ZeroIncomes)
