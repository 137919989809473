import { filter } from 'lodash'
import { ZERO_INCOME_SECTIONS } from 'config'

export default function buildZeroIncomeHouseholdMemberSections(
  householdMembers
) {
  const ANTICIPATED_INCOME_HEADER = 'Anticipated Income'
  const oneOrMoreMembersHaveIncome = householdMembers.some(
    (householdMember) => householdMember.income
  )
  if (oneOrMoreMembersHaveIncome)
    return filter(
      ZERO_INCOME_SECTIONS,
      (section) => section.header == ANTICIPATED_INCOME_HEADER
    )
  return ZERO_INCOME_SECTIONS
}
