import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Yup from 'yup'
import * as Types from 'types'
import {
  AdditionalInformationField,
  RadioGroup,
  CheckboxGroup,
  Textarea,
  OptionalLabel,
} from 'components'
import { StepForm } from 'forms'
import {
  CONSIDERATION_OPTIONS,
  BOOLEAN_STRING_OPTIONS,
  STEP,
  APPLICATION_INFORMATION_QUESTIONS,
  NEW_TENANT_CERT_TYPES,
  FILTER_OUT_QUESTIONS_FOR_STATES,
} from 'config'
import { getAvailableBedroomOptions } from 'utils'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  asset: Types.asset.isRequired,
  certType: PropTypes.string.isRequired,
}

const defaultProps = {}

function ApplicationInformationForm({
  asset,
  onSubmit,
  initialValues,
  certType,
}) {
  const isNewTenant = NEW_TENANT_CERT_TYPES.includes(certType)
  const includeCriminalHistoryQuestions =
    FILTER_OUT_QUESTIONS_FOR_STATES.includes(asset.state)

  const getBedroomSizesSchema = () => {
    const validation = Yup.array().of(Yup.string())
    if (!isNewTenant) return validation

    const errorMessage = 'The number of bedrooms requested is required'
    return validation.min(1, errorMessage).required(errorMessage)
  }

  const getNewTenantSchemaValidation = () => {
    const validation = Yup.string()
    if (!isNewTenant) return validation
    if (includeCriminalHistoryQuestions) return validation

    const errorMessage = 'An answer is required'
    return validation.required(errorMessage)
  }

  const rentalHistoryQuestions = () => {
    // From index 11 to 12
    if (includeCriminalHistoryQuestions) {
      return APPLICATION_INFORMATION_QUESTIONS.slice(11, 12)
    }

    return APPLICATION_INFORMATION_QUESTIONS.slice(10, 12)
  }

  const validationSchema = Yup.object({
    tenantApplication: Yup.object({
      bedroomSizes: getBedroomSizesSchema(),
      crimeConvictions: getNewTenantSchemaValidation(),
      crimeConvictionsComments: Yup.string().when('crimeConvictions', {
        is: 'Yes',
        then: (schema) =>
          schema.required('Household member name and status required'),
      }),
      displaced: getNewTenantSchemaValidation(),
      displacedComments: Yup.string(),
      domesticViolence: getNewTenantSchemaValidation(),
      domesticViolenceComments: Yup.string(),
      evicted: getNewTenantSchemaValidation(),
      evictedComments: Yup.string(),
      expectAdoptions: Yup.string().required('An answer is required'),
      expectAdoptionsComments: Yup.string(),
      expectHouseholdAdditions: Yup.string().required('An answer is required'),
      expectHouseholdAdditionsComments: Yup.string(),
      handicapAccessible: getNewTenantSchemaValidation(),
      homeless: getNewTenantSchemaValidation(),
      homelessComments: Yup.string(),
      minorsAbsentHalfTime: Yup.string().required('An answer is required'),
      minorsAbsentHalfTimeComments: Yup.string(),
      otherRequestsComments: Yup.string(),
      pets: Yup.string().required('An answer is required'),
      petsComments: Yup.string(),
      registeredSexOffender: Yup.string().required('An answer is required'),
      registeredSexOffenderComments: Yup.string().when(
        'registeredSexOffender',
        {
          is: 'Yes',
          then: (schema) =>
            schema.required(
              'Household member name and status and all states resided in are required'
            ),
        }
      ),
      serviceAnimal: Yup.string().required('An answer is required'),
      serviceAnimalComments: Yup.string(),
      veterans: getNewTenantSchemaValidation(),
      veteransComments: Yup.string(),
      visualHearingImpairment: getNewTenantSchemaValidation(),
      visualHearingImpairmentComments: Yup.string(),
    }),
  })

  const filteredQuestionIndices = () => {
    return APPLICATION_INFORMATION_QUESTIONS.reduce((result, _, index) => {
      if (!isNewTenant) {
        if (index >= 0 && index <= 2) return result // Remove questions 1-3
        if (index >= 10 && index <= 15) return result // Remove questions 11-16
      }

      if (!(asset.handicapUnitsCount > 0)) {
        if (index === 1) return result // Remove question at position 2
      }

      if (includeCriminalHistoryQuestions && index == 11) {
        return result
      }

      result.push(index)
      return result
    }, [])
  }

  const questionIndices = filteredQuestionIndices()

  return (
    <StepForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      previousStep={STEP.CONTACT_INFORMATION}
    >
      {({ values }) => (
        <>
          <div className="form-section">
            <div className="heading-wrapper">
              <h2>Unit Requests</h2>
            </div>
            {isNewTenant && (
              <div className="row bottom-border">
                <div className="col-12">
                  <CheckboxGroup
                    name="tenantApplication.bedroomSizes"
                    label={`${questionIndices.indexOf(0) + 1}. ${
                      APPLICATION_INFORMATION_QUESTIONS[0].label
                    }`}
                    options={getAvailableBedroomOptions(asset)}
                    required
                  />
                </div>
              </div>
            )}
            {isNewTenant && asset.handicapUnitsCount > 0 && (
              <div className="row bottom-border">
                <div className="col-12">
                  <RadioGroup
                    name="tenantApplication.handicapAccessible"
                    label={`${questionIndices.indexOf(1) + 1}. ${
                      APPLICATION_INFORMATION_QUESTIONS[1].label
                    }`}
                    options={BOOLEAN_STRING_OPTIONS}
                    required
                  />
                </div>
              </div>
            )}
            {isNewTenant && (
              <AdditionalInformationField
                name="tenantApplication.visualHearingImpairment"
                label={`${questionIndices.indexOf(2) + 1}. ${
                  APPLICATION_INFORMATION_QUESTIONS[2].label
                }`}
                additionalLabelComponent={OptionalLabel}
                additionalName="tenantApplication.visualHearingImpairmentComments"
                answer={values.tenantApplication.visualHearingImpairment}
              />
            )}
            <div className="row bottom-border">
              <div className="col-12">
                <Textarea
                  name="tenantApplication.otherRequestsComments"
                  label={`${questionIndices.indexOf(3) + 1}. ${
                    APPLICATION_INFORMATION_QUESTIONS[3].label
                  }`}
                  additionalLabelComponent={OptionalLabel}
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="heading-wrapper">
              <h2>Pets and Service/Support Animals</h2>
            </div>
            <AdditionalInformationField
              name="tenantApplication.pets"
              label={`1. ${APPLICATION_INFORMATION_QUESTIONS[4].label}`}
              additionalLabelComponent={OptionalLabel}
              additionalName="tenantApplication.petsComments"
              answer={values.tenantApplication.pets}
            />
            <AdditionalInformationField
              name="tenantApplication.serviceAnimal"
              label={`2. ${APPLICATION_INFORMATION_QUESTIONS[5].label}`}
              additionalLabelComponent={OptionalLabel}
              additionalName="tenantApplication.serviceAnimalComments"
              answer={values.tenantApplication.serviceAnimal}
            />
          </div>

          <div className="form-section">
            <div className="heading-wrapper">
              <h2>Household Members</h2>
            </div>
            <AdditionalInformationField
              name="tenantApplication.expectHouseholdAdditions"
              label={`1. ${APPLICATION_INFORMATION_QUESTIONS[6].label}`}
              additionalLabelComponent={OptionalLabel}
              additionalName="tenantApplication.expectHouseholdAdditionsComments"
              answer={values.tenantApplication.expectHouseholdAdditions}
            />
            <AdditionalInformationField
              name="tenantApplication.minorsAbsentHalfTime"
              label={`2. ${APPLICATION_INFORMATION_QUESTIONS[7].label}`}
              additionalLabelComponent={OptionalLabel}
              additionalName="tenantApplication.minorsAbsentHalfTimeComments"
              answer={values.tenantApplication.minorsAbsentHalfTime}
            />
            <AdditionalInformationField
              name="tenantApplication.expectAdoptions"
              label={`3. ${APPLICATION_INFORMATION_QUESTIONS[8].label}`}
              additionalLabelComponent={OptionalLabel}
              additionalName="tenantApplication.expectAdoptionsComments"
              answer={values.tenantApplication.expectAdoptions}
            />
            <AdditionalInformationField
              name="tenantApplication.registeredSexOffender"
              label={`4. ${APPLICATION_INFORMATION_QUESTIONS[9].label}`}
              additionalLabel="Please state the household member name and status and all states resided in"
              additionalName="tenantApplication.registeredSexOffenderComments"
              answer={values.tenantApplication.registeredSexOffender}
              additionalRequired
            />
          </div>

          {isNewTenant && (
            <div className="form-section">
              <div className="heading-wrapper">
                <h2>Rental History</h2>
              </div>
              {rentalHistoryQuestions().map((question, index) => (
                <AdditionalInformationField
                  key={`question-${question.name}-${index}`}
                  name={`tenantApplication.${question.name}`}
                  label={`${index + 1}. ${question.label}`}
                  additionalLabel={question.additionalLabel}
                  additionalName={`tenantApplication.${question.additionalName}`}
                  additionalLabelComponent={
                    !question.additionalRequired && OptionalLabel
                  }
                  answer={`${values.tenantApplication[question.name]}`}
                  additionalRequired={question.additionalRequired}
                />
              ))}
            </div>
          )}

          {isNewTenant && (
            <div className="form-section">
              <div className="heading-wrapper">
                <h2>Other Considerations</h2>
              </div>
              <AdditionalInformationField
                name="tenantApplication.displaced"
                label={`1. ${APPLICATION_INFORMATION_QUESTIONS[12].label}`}
                additionalLabelComponent={OptionalLabel}
                additionalName="tenantApplication.displacedComments"
                options={CONSIDERATION_OPTIONS}
                answer={values.tenantApplication.displaced}
              />
              <AdditionalInformationField
                name="tenantApplication.homeless"
                label={`2. ${APPLICATION_INFORMATION_QUESTIONS[13].label}`}
                additionalLabelComponent={OptionalLabel}
                additionalName="tenantApplication.homelessComments"
                options={CONSIDERATION_OPTIONS}
                answer={values.tenantApplication.homeless}
              />
              <AdditionalInformationField
                name="tenantApplication.veterans"
                label={`3. ${APPLICATION_INFORMATION_QUESTIONS[14].label}`}
                additionalLabelComponent={OptionalLabel}
                additionalName="tenantApplication.veteransComments"
                options={CONSIDERATION_OPTIONS}
                answer={values.tenantApplication.veterans}
              />
              <AdditionalInformationField
                name="tenantApplication.domesticViolence"
                label={`4. ${APPLICATION_INFORMATION_QUESTIONS[15].label}`}
                additionalLabelComponent={OptionalLabel}
                additionalName="tenantApplication.domesticViolenceComments"
                options={CONSIDERATION_OPTIONS}
                answer={values.tenantApplication.domesticViolence}
              />
            </div>
          )}
        </>
      )}
    </StepForm>
  )
}

ApplicationInformationForm.propTypes = exact(propTypes)
ApplicationInformationForm.defaultProps = defaultProps

export default React.memo(ApplicationInformationForm)
