import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { first, groupBy, isEmpty, keys } from 'lodash'
import {
  convertBoolToString,
  buildZeroIncomeHouseholdMemberSections,
} from 'utils'

const propTypes = {
  zeroIncomes: PropTypes.arrayOf(Types.zeroIncome).isRequired,
  householdMembers: PropTypes.arrayOf(Types.householdMember).isRequired,
}

const defaultProps = {}

function ReviewZeroIncomes({ zeroIncomes, householdMembers }) {
  if (isEmpty(zeroIncomes)) {
    return <p>There are no members with zero income in your household.</p>
  }

  const zeroIncomesGroupedByMember = groupBy(zeroIncomes, 'householdMemberName')
  const memberNames = keys(zeroIncomesGroupedByMember)

  return memberNames.map((memberName, index) => {
    const zeroIncomes = zeroIncomesGroupedByMember[memberName]
    const isLastMember = index === memberNames.length - 1
    return (
      <React.Fragment key={memberName}>
        <div>
          <h4>{memberName}</h4>
          {buildZeroIncomeHouseholdMemberSections(householdMembers).map(
            ({ header, questions }) => (
              <div key={header}>
                <h5>{header}</h5>
                {questions.map(({ questionType, label }) => {
                  const matchingZeroIncomes = zeroIncomes.filter(
                    (income) => income.shortenedType === questionType
                  )
                  if (isEmpty(matchingZeroIncomes)) return null

                  const zeroIncomeApplies = first(matchingZeroIncomes).applies

                  return (
                    <dl key={questionType}>
                      <dt>{label}</dt>
                      <dd>{convertBoolToString(zeroIncomeApplies)}</dd>
                      {zeroIncomeApplies &&
                        matchingZeroIncomes.map(({ id, additionalNotes }) => (
                          <div key={id} className="income-details">
                            {!isEmpty(additionalNotes) && (
                              <>
                                <dt>{'Notes'}</dt>
                                <dd>{additionalNotes}</dd>
                              </>
                            )}
                          </div>
                        ))}
                    </dl>
                  )
                })}
              </div>
            )
          )}
        </div>
        {!isLastMember && <div className="bottom-border" />}
      </React.Fragment>
    )
  })
}

ReviewZeroIncomes.propTypes = exact(propTypes)
ReviewZeroIncomes.defaultProps = defaultProps

export default React.memo(ReviewZeroIncomes)
