export const STEP = {
  HOUSEHOLD_MEMBERS: {
    name: 'Household Members',
    path: '/household_members',
  },
  CONTACT_INFORMATION: {
    name: 'Contact Information',
    path: '/contact_information',
  },
  APPLICATION_INFORMATION: {
    name: 'Application Information',
    path: '/application_information',
  },
  STUDENT_STATUS: {
    name: 'Student Status',
    path: '/student_status',
  },
  ZERO_INCOME: {
    name: 'Zero Income',
    path: '/zero_incomes',
  },
  HOUSEHOLD_INCOME: {
    name: 'Household Income',
    path: '/household_incomes',
  },
  HOUSEHOLD_ASSETS: {
    name: 'Household Assets',
    path: '/household_assets',
  },
  PERSONAL_VERIFICATION: {
    name: 'Personal Verification',
    path: '/personal_verification',
  },
  REVIEW_AND_SIGN: {
    name: 'Review & Sign',
    path: '/review_and_sign',
  },
  INTERVIEW: {
    name: 'Interview',
    path: '/interview',
  },
}

// DO NOT, I REPEAT, DO NOT CHANGE ANY OF THESE PERSONAL_VERIFICATION_FILE_CATEGORIES OR THE APP WILL BREAK
export const PERSONAL_VERIFICATION_FILE_CATEGORIES = {
  socialSecurityCards: 'SSC',
  birthCertificates: 'BC',
  additionalDocuments: 'AD',
}

export const APPLICATION_OVERVIEW_PATH = '/application_overview'

export const APPLICATION_COMPLETED_PATH = '/application_completed'

export const ROOM_SIZES = {
  studioUnitsCount: 'Studio',
  oneBdUnitsCount: '1 Bedroom',
  twoBdUnitsCount: '2 Bedrooms',
  threeBdUnitsCount: '3 Bedrooms',
  fourBdUnitsCount: '4 Bedrooms',
  fiveBdUnitsCount: '5 Bedrooms',
  sixBdUnitsCount: '6 Bedrooms',
}

export const MOBILE_WIDTH = 768

export const HOUSEHOLD_ASSETS_QUESTIONS = [
  {
    name: 'checkingAccount',
    label:
      'Do any members of your household have a Checking Account (Please include estimated six month average balance)?',
  },
  {
    name: 'savingsAccount',
    label: 'Do any members of your household have a Savings Account?',
  },
  {
    name: 'cdMoneyMarketTreasury',
    label:
      'Do any members of your household have Certificates of Deposit, Money Market Accounts and/or Treasury Funds?',
  },
  {
    name: 'capitalInvestments',
    label: 'Do any members of your household have Capital Investments?',
  },
  {
    name: 'stocksBondsSecurities',
    label:
      'Do any members of your household have Stocks, Bonds and/or Securities?',
  },
  {
    name: 'retirementAccounts',
    label:
      'Do any members of your household have 401K, IRA and/or KEOGH Accounts?',
  },
  {
    name: 'safetyDepositBox',
    label: 'Do any members of your household have Safety Deposit Box?',
  },
  {
    name: 'lifeInsuranceTrust',
    label:
      'Do any members of your household have Whole Life Insurance Policy and/or Trust?',
  },
  {
    name: 'pensionAnnuity',
    label:
      'Do any members of your household have Pension, Retirement and/or Annuity Accounts?',
  },
  {
    name: 'realEstate',
    label:
      'Do any members of your household have Real Estate (If Yes, List Address)?',
    assetLabels: {
      amount: 'Real Estate Value',
      source: 'Asset Location',
      income: 'Income from Real Estate',
    },
  },
  {
    name: 'realEstatePayment',
    label:
      'Do any members of your household receive any payment for any real estate (home, land, etc) you sold by contract or deed?',
  },
  {
    name: 'collectibles',
    label:
      'Do you have any coin collections, antique cars, gems/jewelry, stamps, or other items that are being held as an investment (wedding rings and personal jewelry are not included)?',
  },
  {
    name: 'jointAssets',
    label: 'Any assets held jointly with another person or party?',
  },
  {
    name: 'disposalOfAssets',
    label:
      'Have you disposed of any assets for less than fair market value in the past two (2) years?',
  },
  {
    name: 'cashOnHand',
    label: 'Do any members of your household have Cash on Hand?',
    assetLabels: {
      source: 'Notes',
    },
  },
  {
    name: 'cryptoCurrency',
    label: 'Do any members of your household have Crypto Currency?',
  },
  {
    name: 'p2pFunds',
    label:
      'Do any members of your household Funds Held in a Peer-to-Peer Application (Cash App, PayPal)?',
  },
  {
    name: 'healthSavingsAccount',
    label:
      'Do any members of your household have a Health or Medical Savings Account (HSA or MSA)?',
  },
]

export const INITIAL_FORM_VALUES = {
  HOUSEHOLD_MEMBERS: {
    firstName: '',
    lastName: '',
    relationship: '',
    dateOfBirth: '',
    student: '',
    disabled: '',
    ssn: '',
    income: '',
    race: '',
    ethnicity: '',
    maritalStatus: '',
    gender: '',
    eligibleCitizen: '',
    livedInOtherStates: '',
  },
  HOUSEHOLD_ASSETS: HOUSEHOLD_ASSETS_QUESTIONS.map(({ name }) => ({
    amount: '',
    applies: '',
    income: '',
    source: '',
    shortenedType: name,
  })),
  HOUSEHOLD_INCOMES: {
    amount: '',
    applies: '',
    source: '',
    additionalDetails: '',
    frequency: '',
    files: '',
  },
  ZERO_INCOMES: {
    additionalNotes: '',
    applies: '',
  },
}

export const APPLICATION_INFORMATION_QUESTIONS = [
  { label: 'Number of Bedrooms Requested', name: 'bedroomSizes' },
  {
    label: 'Handicap Accessible Unit Requested?',
    name: 'handicapAccessible',
  },
  {
    label: 'Do you need an accommodation for Visual or Hearing Impairments?',
    name: 'visualHearingImpairment',
  },
  {
    label:
      'Other Requests (e.g., Reasonable Accommodations, Accessible Features, etc)',
    name: 'otherRequestsComments',
  },
  { label: 'Does any member of the household have a pet?', name: 'pets' },
  {
    label: 'Does any member of the household have a service or support animal?',
    name: 'serviceAnimal',
  },
  {
    label:
      'Do you expect any additions to the household in the next year (e.g., children, spouses, etc.)?',
    name: 'expectHouseholdAdditions',
  },
  {
    label:
      'Do any minor members of the household spend less than 50% of their time in the unit?',
    name: 'minorsAbsentHalfTime',
  },
  {
    label:
      'Are any adoptions or foster children expected in the next 12 months?',
    name: 'expectAdoptions',
  },
  {
    label:
      'Are any members of the household required to register with any state lifetime sex offender for any state or other registry?',
    name: 'registeredSexOffender',
  },
  {
    label:
      'Have you or any member of your household been convicted of any crimes?',
    name: 'crimeConvictions',
    additionalLabel: 'Please state the household member name and status',
    additionalName: 'crimeConvictionsComments',
    additionalRequired: true,
  },
  {
    label: 'Have you ever been evicted from a rental unit?',
    name: 'evicted',
    additionalLabel: undefined,
    additionalName: 'evictedComments',
  },
  {
    label: 'Are you being displaced from your current housing?',
    name: 'displaced',
  },
  {
    label: 'Are you currently experiencing homelessness?',
    name: 'homeless',
  },
  {
    label: 'Are any members of the household Veterans of Military Service?',
    name: 'veterans',
  },
  {
    label:
      'Are any of the members of the household currently a victim of domestic violence, sexual assault, dating violence, or stalking?',
    name: 'domesticViolence',
  },
]

export const STUDENT_ADDITIONAL_QUESTIONS = [
  {
    label:
      'Was this household member receiving Section 8 assistance as of 11/30/05?',
    name: 'studentSection8Assistance',
  },
  {
    label: 'Is this household member a graduate or professional student?',
    name: 'studentGraduateOrProfessionalStudent',
  },
  {
    label: 'Is this household member a veteran of the United States Military?',
    name: 'studentVeteranStatus',
  },
  {
    label:
      'Does this household member have a dependent child living in the household?',
    name: 'studentMinorMemberInHousehold',
  },
  {
    label:
      'Does this household member have any dependents other than a child or spouse?',
    name: 'studentOtherDependents',
  },
  {
    label:
      'Was this household member an orphan or ward of the state through the age of 18?',
    name: 'studentOrphan',
  },
  {
    label:
      'Was this household member previously enrolled in a foster care program under Part B or E of Title IV of the SSA?',
    name: 'studentFosterCare',
  },
  {
    label: 'Is this household member living with their parents?',
    name: 'studentLivingWithParents',
  },
  {
    label:
      'Has this household member established a separate household from their parents for at least one year?',
    name: 'studentSeperateHouseholdFor1Year',
  },
  {
    label:
      "Are this household member's parents eligible for Section 8 Assistance?",
    name: 'studentParentsEligibleForSection8',
  },
  {
    label:
      'Does this household member receive financial assistance for their education?',
    name: 'studentFinancialAssistance',
  },
  {
    label: 'Does this household member receive TANF?',
    name: 'studentTanfIncome',
  },
  {
    label: 'Is this household member married and filing a joint return?',
    name: 'studentMarriedAndJointReturn',
  },
  {
    label:
      'Is this household member enrolled in a Job Training Program under the Job Training Partnership Act (Federal, State or Local)?',
    name: 'studentJobTrainingProgram',
  },
  {
    label:
      'Is this household member a single parent who claims their minor children as dependents and were not claimed as a dependent by someone else on a tax return?',
    name: 'studentDependentFilingStatus',
  },
  {
    label: "Is this household member claimed on their parent's tax return?",
    name: 'studentOnParentsTaxReturn',
  },
]

export const RELATIONSHIP_OPTIONS = [
  'Head of Household',
  'Spouse',
  'Co-Head',
  'Minor Child',
  'Foster Child/Adult',
  'Live-in Aide',
  'Other Adult Member',
]

export const EMERGENCY_CONTACT_RELATIONSHIPS = [
  'Aunt',
  'Brother',
  'Cousins',
  'Daughter',
  'Father',
  'Grandfather',
  'Grandmother',
  'Grandson',
  'Husband',
  'Mother',
  'Nephew',
  'Niece',
  'Sister',
  'Son',
  'Uncle',
  'Wife',
  'Other',
]

export const MAX_FILE_SIZE_MB = 30
export const FILE_SIZE_TEXT = 'Max file size is 30MB'

export const BOOLEAN_OPTIONS = [
  { key: 'Yes', value: true },
  { key: 'No', value: false },
]

export const BOOLEAN_STRING_OPTIONS = ['Yes', 'No']

export const CONSIDERATION_OPTIONS = ['Yes', 'No', 'Prefer not to answer']

export const BOOLEAN_WITH_UNCERTAIN_OPTIONS = ['Yes', 'No', 'Uncertain']

export const RACE_OPTIONS = [
  'White',
  'Black / African American',
  'American Indian or Alaskan Native',
  'Native Hawaiian or Other Pacific Islander',
  'Asian',
  'Prefer not to answer',
]

export const ETHNICITY_OPTIONS = [
  'Hispanic',
  'Non-Hispanic',
  'Prefer not to answer',
]

export const GENDER_OPTIONS = [
  'Male',
  'Female',
  'Non-Binary',
  'Prefer not to answer',
]

export const MARITAL_STATUS_OPTIONS = [
  'Single',
  'Married',
  'Divorced',
  'Separated',
  'Widowed',
  'Estranged',
]

export const ENROLLMENT_OPTIONS = ['Full-Time', 'Part-Time']

export const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const SAVE_ERROR_MESSAGE =
  'There was an issue saving your changes. Please try again or Contact us if the issue persists.'

export const DOCUMENTS_ERROR_MESSAGE =
  'There was an issue loading your uploaded documents. Please refresh or Contact us if the issue persists.'

export const SIGNATURES_ERROR_MESSAGE =
  'There was an issue loading your saved signatures. Please refresh or Contact us if the issue persists.'

export const HOUSEHOLD_INCOME_SECTIONS = [
  {
    header: 'Working Income',
    sourceHeader: 'Income Source',
    questions: [
      {
        questionType: 'wages_and_salaries',
        label:
          'Does this individual receive Wages and Salaries (Including any overtime, tips, bonuses, commission, etc.)?',
        uploads: {
          uploadHeader: 'Paystubs',
          uploadSubHeader:
            'In this section, you need to provide 6 of your most recent paystubs.',
        },
      },
      {
        questionType: 'income_in_cash',
        label: 'Does this individual receive employment income paid in cash?',
        uploads: {
          uploadHeader: 'Personal Declaration of Income',
          uploadSubHeader:
            'In this section, you need to provide a signed statement in your own words',
        },
      },
      {
        questionType: 'self_employment_income',
        label:
          'Does this individual receive Self-employment income (Either as an independent contractor or a business owner)?',
        uploads: {
          uploadHeader: 'Last Tax Return or Current Income Statement',
          uploadSubHeader: '',
        },
        subQuestions: [
          {
            subQuestionType: 'selfEmploymentDetails',
            label:
              'Provide Details (e.g., Name of Business or Independent Contractor)',
            displayType: 'textarea',
            style: 'col-12',
          },
        ],
      },
    ],
  },
  {
    header: 'Fixed Income',
    sourceHeader: 'Income Source',
    questions: [
      {
        questionType: 'public_assistance',
        label: 'Does this individual receive Public Assistance (TANF, AFDC)?',
        uploads: {
          uploadHeader: 'Award or Budget Letter',
          uploadSubHeader:
            'In this section, you need to provide record of an award or budget letter.',
        },
        subQuestions: [
          {
            subQuestionType: 'countryIssued',
            label: 'In What County Was the Assistance Issued?',
            displayType: 'string',
            style: 'col-12',
          },
        ],
      },
      {
        questionType: 'child_support',
        label:
          'Does this individual receive or is entitled to receive child support or alimony?',
        uploads: {
          uploadHeader:
            'Child Support Order and Record of Recently Received Payments or Personal Declaration of Income ',
          uploadSubHeader:
            'In this section, you need to provide a signed statement in your own words.',
        },
        indentSection: true,
        subQuestions: [
          {
            subQuestionType: 'courtOrderedSupport',
            label: 'Court Ordered Child Support or Alimony',
            displayType: 'additionalInformationField',
            options: BOOLEAN_OPTIONS,
            style: 'col-12',
          },
          {
            subQuestionType: 'lessThanCourtOrderedAmount',
            label: 'Receiving Less than Court Ordered Amount',
            displayType: 'additionalInformationField',
            options: BOOLEAN_OPTIONS,
            style: 'col-12',
          },
          {
            subQuestionType: 'receivingInformalCash',
            label: 'Receiving Informal Cash Payments or Recurring Gifts',
            displayType: 'additionalInformationField',
            options: BOOLEAN_OPTIONS,
            style: 'col-12',
          },
          {
            subQuestionType: 'supportInGoods',
            label:
              'Child Support in the form of Goods and Services (Food, Clothes)   ',
            displayType: 'additionalInformationField',
            options: BOOLEAN_OPTIONS,
            style: 'col-12',
          },
        ],
      },
      {
        questionType: 'unemployment_benefits',
        label:
          'Does this individual receive Unemployment Benefits or Severance Pay?',
        uploads: {
          uploadHeader: 'Current Benefit Letter',
          uploadSubHeader: '',
        },
      },
      {
        questionType: 'social_security_income',
        label:
          'Does this individual receive Social Security or Supplemental Security Income (Including State Supplemental Program)?',
        uploads: {
          uploadHeader: 'Award or Budget Letter',
          uploadSubHeader:
            'In this section, you need to provide current statement of awarded gross benefit amount.',
        },
      },
      {
        questionType: 'pension_or_annuity',
        label: 'Does this individual receive Pension or Annuity?',
        uploads: {
          uploadHeader: 'Current Annuity Statement',
          uploadSubHeader:
            'In this section, you need to provide current statement of awarded gross benefit amount.',
        },
      },
      {
        questionType: 'receive_contributions',
        label:
          'Does this individual receive regular cash and/or non-cash contributions from someone outside the household?',
        uploads: {
          uploadHeader:
            'Personal Declaration (e.g., signed statement in your own words)',
          uploadSubHeader:
            'In this section, you need to provide the name of person providing contribution and nature of contribution if not cash.',
        },
      },
      {
        questionType: 'workers_compensation',
        label: "Does this individual receive worker's Compensation?",
        uploads: {
          uploadHeader: 'Award or Budget Letter',
          uploadSubHeader:
            'In this section, you need to provide current statement of awarded gross benefit amount.',
        },
      },
    ],
  },
  {
    header: 'Additional Income',
    sourceHeader: 'Income Source',
    questions: [
      {
        questionType: 'armed_forces_pay',
        label:
          'Does this individual receive any regular pay for a member of the Armed Forces?',
        uploads: {
          uploadHeader: 'Current Monthly LES',
          uploadSubHeader: '',
        },
      },
      {
        questionType: 'verterans_benefits',
        label: "Does this individual receive Veterans' Benefits?",
        uploads: {
          uploadHeader: 'Award or Budget Letter',
          uploadSubHeader:
            'In this section, you need to provide current statement of awarded gross benefit amount.',
        },
      },
      {
        questionType: 'student_financial_aid',
        label: 'Does this individual receive student Financial Aid?',
        requirements: ['fundingProgram', 'student'],
        uploads: {
          uploadHeader:
            'Financial Aid Statement (Only Needed if Household is Applying for Section 8 or Voucher)',
          uploadSubHeader:
            'In this section, you need to provide Name of Educational Institution or Financial Aid Provider (if aid is provided directly by an individual, such as a person living outside the home).',
        },
      },
      {
        questionType: 'inheritance_payments',
        label:
          'Does this individual receive regular payments from inheritance, insurance settlements, lottery winnings, etc.?',
        uploads: {
          uploadHeader:
            'Current Benefit Statement Outlining the Frequency of Payments',
          uploadSubHeader: '',
        },
      },
      {
        questionType: 'income_from_investments',
        label:
          'Does this individual receive Income from Investments (Stocks, Bonds, etc.)?',
        uploads: {
          uploadHeader: 'Most Current Statement',
          uploadSubHeader:
            'In this section, you need to provide current monthly/quarterly/annual statement.',
        },
      },
      {
        questionType: 'income_from_real_estate',
        label: 'Does this individual receive Income from Real Estate?',
        uploads: {
          uploadHeader: 'Copy of Lease or Tax Return',
          uploadSubHeader:
            'In this section, you need to provide copy of lease, tax returns, and applicable mortgage statement, tax bill, and maintenance expenses paid.',
        },
      },
    ],
  },
  {
    header: 'Expenses',
    sourceHeader: 'Expense Source',
    questions: [
      {
        questionType: 'health_insurance_out_of_pocket',
        label: 'Does this individual pay for Health Insurance out of pocket?',
        sourceLabel: 'Name of Provider',
        requirements: ['fundingProgram', 'disabledOr62'],
        uploads: {
          uploadHeader: 'Most Current Statement from Insurance Provider',
          uploadSubHeader: '',
        },
      },
      {
        questionType: 'prescription_co_payments',
        label:
          'Does this individual pay Prescription Drug Co-Payments out of pocket?',
        sourceLabel: 'Provide name of Pharmacy(s) used',
        requirements: ['fundingProgram', 'disabledOr62'],
        uploads: {
          uploadHeader: 'Most Current Statement from Insurance Provider',
          uploadSubHeader: '',
        },
      },
      {
        questionType: 'medicare_monthly_payments',
        label:
          'Does this individual pay for Medicare Monthly Payments out of pocket?',
        omitSource: true,
        requirements: ['fundingProgram', 'disabledOr62'],
        uploads: {
          uploadHeader: 'Copy of Medicare Award Letter or Expense Deduction',
          uploadSubHeader:
            'In this section, you need to provide a copy of your Medicare Award Letter or Expense Deduction.',
        },
      },
      {
        questionType: 'out_of_pocket_for_physician',
        label:
          'If this individual sees a physician regularly, do they have an out of pocket cost for that visit not covered by insurance?',
        sourceLabel: 'Name of Physician',
        requirements: ['fundingProgram', 'disabledOr62'],
        uploads: {
          uploadHeader: 'Statement from Physician or Doctor Office',
          uploadSubHeader:
            "In this section, you need to provide a Statement from your Physician or Doctor's Office.",
        },
      },
      {
        questionType: 'outstanding_medical_payments',
        label:
          'Does this individual have outstanding Medical bills for which they are making monthly payments out of pocket?',
        sourceLabel:
          "Name of Provider whom you've entered into payment arrangements with",
        requirements: ['fundingProgram', 'disabledOr62'],
        uploads: {
          uploadHeader: 'Statement from Physician or Doctor Office',
          uploadSubHeader:
            "In this section, you need to provide a Statement from your Physician or Doctor's Office.",
        },
      },
      {
        questionType: 'disability_attendant_care',
        label:
          'Does this individual pay for Disability Attendant Care out of pocket?',
        sourceLabel: 'Name of Provider',
        requirements: ['fundingProgram'],
        uploads: {
          uploadHeader: 'Statement from Physician or Doctor Office',
          uploadSubHeader:
            "In this section, you need to provide a Statement from your Physician or Doctor's Office.",
        },
      },
      {
        questionType: 'disability_auxiliary_apparatuses',
        label:
          'Does this individual pay for Disability Auxiliary Apparatuses out of pocket?',
        omitSource: true,
        requirements: ['fundingProgram'],
        uploads: {
          uploadHeader:
            'Receipt for purchase or monthly payments for auxiliary apparatus.  ',
          uploadSubHeader:
            'In this section, you need to provide a Receipt for purchase or monthly payments for auxiliary apparatus.',
        },
      },
      {
        questionType: 'childcare_out_of_pocket',
        label: 'Does this individual pay for childcare out of pocket?',
        sourceLabel: 'Name of Provider',
        requirements: ['fundingProgram'],
        uploads: {
          uploadHeader: 'Proof of expense, receipts, statement, etc.',
          uploadSubHeader:
            'In this section, you need to provide Proof of expense, receipts, statement, etc.',
        },
        subQuestions: [
          {
            subQuestionType: 'addressOfProvider',
            label: 'Address of Provider',
            displayType: 'string',
            style: 'col-12',
          },
          {
            subQuestionType: 'address2OfProvider',
            label: 'Address Line 2',
            optional: true,
            displayType: 'string',
            style: 'col-12',
          },
          {
            subQuestionType: 'city',
            label: 'City',
            displayType: 'string',
            style: 'col-6',
          },
          {
            subQuestionType: 'state',
            label: 'State',
            displayType: 'select',
            options: US_STATES,
            style: 'col-6',
          },
          {
            subQuestionType: 'zip',
            label: 'Zip Code',
            displayType: 'string',
            style: 'col-6',
          },
          {
            subQuestionType: 'childcareAllowsForWork',
            label:
              'Does this childcare allow you to attend work or look for work?',
            displayType: 'radio',
            options: BOOLEAN_STRING_OPTIONS,
            style: 'col-12 left',
          },
          {
            subQuestionType: 'childcareAllowForLearning',
            label:
              'Does this childcare allow you to attend job training or school? ',
            displayType: 'radio',
            options: BOOLEAN_STRING_OPTIONS,
            style: 'col-12 left',
          },
          {
            subQuestionType: 'allChildrenIncludedInExpense',
            label: 'Are all minor children included in this expense?',
            displayType: 'radio',
            options: BOOLEAN_STRING_OPTIONS,
            style: 'col-12 left',
          },
          {
            subQuestionType: 'childrenIncluded',
            label:
              'If childcare is not for all minor children, please state what children are included.',
            displayType: 'string',
            optional: true,
            style: 'col-12 left',
          },
        ],
      },
    ],
  },
]

export const ZERO_INCOME_SECTIONS = [
  {
    header: 'Zero Income Questionnaire',
    sourceHeader: 'Income Source',
    questions: [
      {
        questionType: 'assistance_with_rent',
        label: 'Do you receive assistance paying for rent?',
      },
      {
        questionType: 'assistance_with_utilities',
        label: 'Do you receive assistance paying for utilities?',
      },
      {
        questionType: 'assistance_with_food',
        label:
          'Do you receive assistance paying for groceries/food/toiletries?',
      },
      {
        questionType: 'assistance_with_clothing',
        label: 'Do you receive assistance paying for clothing?',
      },
      {
        questionType: 'assistance_with_transportation',
        label: 'Do you receive assistance paying for transportation?',
      },
      {
        questionType: 'assistance_with_internet',
        label: 'Do you receive assistance paying for internet/cable?',
      },
      {
        questionType: 'assistance_with_phone',
        label: 'Do you receive assistance paying for phone/cellphone?',
      },
    ],
  },
  {
    header: 'Anticipated Income',
    sourceHeader: 'Zero Income Source',
    questions: [
      {
        questionType: 'expected_to_gain_employment',
        label:
          'Does this household member expect to gain employment in the next 12 months?',
      },
      {
        questionType: 'currently_have_job_offer',
        label:
          'Does this household member currently have a job offer with a start date and wage?',
      },
      {
        questionType: 'potential_earnings_if_employed',
        label:
          "Based on this household member's employment history and educational training, please provide an estimate of potential earnings if the household member were to find employment?",
      },
    ],
  },
]

export const FREQUENCY_OPTIONS = [
  'Daily',
  'Weekly',
  'Biweekly',
  'Monthly',
  'Annually',
]

export const NEW_TENANT_CERT_TYPES = ['MI', 'IC']

export const INTERVIEWER_STATUS = {
  AVAILABLE: {
    statusClassName: 'circle color-primary-light',
    statusLabel: 'Interviewer Available',
    statusDescription:
      'An interviewer is currently available. Click the button below to join the Zoom waiting room and then begin your interview session.',
    isAvailable: true,
  },
  AVAILABLE_SOON: {
    statusClassName: 'circle color-yellow',
    statusLabel: 'Available Soon',
    statusDescription:
      'Interviewers are online, but are currently booked up. Please come back in a few minutes, refresh the page and try again. Interviewers are available Monday-Friday between the hours of 9am ET to 4pm ET.',
    isAvailable: false,
  },
  UNAVAILABLE: {
    statusClassName: 'circle color-red',
    statusLabel: 'Interviewer Unavailable',
    statusDescription:
      'There are no interviewers available because you are outside of working hours. Come back Monday-Friday between the hours of 9am ET to 4pm ET.',
    isAvailable: false,
  },
}

export const FILTER_OUT_QUESTIONS_FOR_STATES = ['NJ']
