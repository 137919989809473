import React from 'react'
import PropTypes from 'prop-types'
import { Textarea, RadioGroup } from 'components'
import { BOOLEAN_STRING_OPTIONS } from 'config'
import exact from 'prop-types-exact'
import classNames from 'classnames'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  additionalName: PropTypes.string.isRequired,
  additionalLabel: PropTypes.string,
  additionalLabelComponent: PropTypes.object,
  additionalRequired: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  additionalLabel: 'Additional Information',
  additionalRequired: false,
  options: BOOLEAN_STRING_OPTIONS,
}

function AdditionalInformationField({
  name,
  label,
  additionalName,
  additionalLabel,
  additionalLabelComponent: AdditionalLabelComponent,
  additionalRequired,
  answer,
  options,
}) {
  const askForAdditionalInfo = answer === 'Yes'

  const conditionalProps = {}

  if (AdditionalLabelComponent) {
    conditionalProps.labelComponent = AdditionalLabelComponent
  }

  return (
    <>
      <div
        className={classNames('row', {
          'bottom-border': !askForAdditionalInfo,
        })}
      >
        <div className="col-12">
          <RadioGroup name={name} label={label} options={options} required />
        </div>
      </div>

      {askForAdditionalInfo && (
        <div className="row bottom-border">
          <div className="col-12">
            <Textarea
              name={additionalName}
              label={additionalLabel}
              required={additionalRequired}
              {...conditionalProps}
            />
          </div>
        </div>
      )}
    </>
  )
}

AdditionalInformationField.propTypes = exact(propTypes)
AdditionalInformationField.defaultProps = defaultProps

export default React.memo(AdditionalInformationField)
